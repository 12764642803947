import React, { useState, useEffect } from "react";
import "@/fonts/Manrope/Manrope.scss";
import "@/styles/reset.scss";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type { AppProps } from "next/app";
import { yTalkMe } from "@/utils/yandexMetrika";

export default function App({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(() => new QueryClient());
  const [chatButton, setChatButton] = useState<any>(null);

  useEffect(() => {
    if (chatButton) return;

    const interval = setInterval(() => {
      const support = document.getElementById("supportTrigger");
      if (support) setChatButton(support);
    }, 1000);

    return () => clearInterval(interval);
  }, [chatButton]);

  useEffect(() => {
    if (!chatButton) return;
    chatButton.addEventListener("click", () => yTalkMe());

    return () => chatButton.removeEventListener("click");
  }, [chatButton]);

  return (
    <QueryClientProvider client={queryClient}>
      <Component {...pageProps} />
    </QueryClientProvider>
  );
}
