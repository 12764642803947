// @ts-nocheck - Yandex Metrika

export const YANDEX_METRIKA_ID = 96074877;

export const yCreateOrder = () => {
  if (window?.ym) {
    window.ym(YANDEX_METRIKA_ID, "reachGoal", "create");
  }
};

export const yTalkMe = () => {
  if (window?.ym) {
    window.ym(YANDEX_METRIKA_ID, "reachGoal", "talkme");
  }
};

export const yOpenTg = () => {
  if (window?.ym) {
    window.ym(YANDEX_METRIKA_ID, "reachGoal", "tg");
  }
};

export const yOpenTgChannel = () => {
  if (window?.ym) {
    window.ym(YANDEX_METRIKA_ID, "reachGoal", "channel");
  }
};
